.dashed {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='24px'><text x='3' y='11' fill='%23E5E3D4' font-size='18px'>—</text></svg>");
  background-repeat: repeat-x;
  background-position: left center;
  margin-top: -3px;
}

@media (max-width: 760px) {
  .dashed {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='16px'><text x='3' y='11' fill='%23E5E3D4' font-size='12px'>—</text></svg>");
  }
}

::-webkit-scrollbar-track
{
	border: 1px solid black;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;	
}

